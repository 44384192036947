import React, { useState } from 'react';

import {
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useStyles from 'styles/forms';
import {isValid} from "../../helpers/utils";
import {toast} from "react-toastify";

const AddCostManager = ({ open, handleClose, costManager, createCostManager, title, uniqueCheck }) => {
  const classes = useStyles();

  const [form, setForm] = useState(costManager);
  const formCopy = { ...form };
  const loading = useSelector((state) => state.costManager.loading);
  const [nameError, setNameError] = useState(false);

  const handleCloseModal = () => {
    setForm('');
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-costmanager"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsive}
      >
        <Typography className={classes.assetTitle}>{title}</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          autoFocus
          variant="outlined"
          className={classes.textFieldPortfolio}
          value={form}
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          multiline
          disabled={loading}
          onChange={(e) => {
            setForm(e.target.value)
            if (uniqueCheck(e.target.value)) {
              setNameError(true);
            } else {
              setNameError(false);
            }
          }}
          error={nameError}
          helperText={"Name should be unique"}
        />
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <CancelButton onClick={handleCloseModal} />
          <SaveButton
            onClick={() => {
              if (nameError) {
                return toast.error('Name should be unique');
              }

              createCostManager(form)
            }}
            disabled={isEqual(formCopy, form)}
            loading={loading}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddCostManager;

AddCostManager.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  createCostManager: PropTypes.func.isRequired,
  costManager: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
