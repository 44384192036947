/* eslint-disable no-unused-vars */
import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { numberWithCommas } from 'helpers/utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

import useStyles from '../footfallstyles';

const EnterFootfall = ({ form, date, handleSave, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(form ?? '');
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  const handleSaveData = () => {
    dispatch(actions.createFootfall(asset.id, value, date, id))
      .then(() => {
        handleSave();
        toast.success('Footfall Added!');
      })
      .catch((err) => {
        toast.error(err);
      });
    setOpen(false);
  };

  const handleEdit = () => {
    setOpen(true);
  };

  const renderFootfall = () => {
    if (!form) {
      return (
        <Typography onClick={() => setOpen(true)} className={classes.textPrimary}>
          Enter data
        </Typography>
      );
    }

    return (
      <Box display="flex" alignItems="center" justifyContent="space-around">
        <Typography className={classes.footfallitem}>{numberWithCommas(form)}</Typography>
        <EditIcon onClick={handleEdit} className={classes.editIcon} />
      </Box>
    );
  };

  return (
    <>
      {open && (
        <Box display="flex" alignItems="center" justifyContent="space-around" width={300}>
          <TextField
            label="People"
            variant="outlined"
            type="number"
            className={classes.textField}
            InputProps={{
              classes: {
                root: classes.input,
                input: classes.textInput
              }
            }}
            style={{ width: 110 }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Typography className={classes.textSecondary} onClick={() => setOpen(false)}>
            CANCEL
          </Typography>
          <Typography className={classes.textSecondary} color="primary" onClick={handleSaveData}>
            SAVE
          </Typography>
        </Box>
      )}
      {!open && renderFootfall()}
    </>
  );
};

export default EnterFootfall;

EnterFootfall.propTypes = {
  form: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  handleSave: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};
