import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const PortfolioModal = () => {
  const classes = useStyles();
  const portfolio = useSelector((state) => state.modals.modalProps.portfolio);
  const [form, setForm] = useState(portfolio);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.portfolios.loading);
  const organizationId = useSelector((state) => state.user.user.organizationid);

  const handleSave = () => {
    dispatch(actions.createPortfolio(form, organizationId))
      .then(() => {
        dispatch(actions.getPortfolios());
        dispatch(closeModal());
        toast.success('Success');
        setForm((prevState) => ({
          ...prevState,
          name: ''
        }));
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      name: e.target.value
    }));
  };

  return (
    <ModalWrapper
      size="lg"
      header={form.id ? 'Edit Portfolio' : 'Create Portfolio'}
      handleSave={handleSave}
      disabled={isEqual(portfolio, form)}
      loading={loading}
    >
      <TextField
        fullWidth
        label="Portfolio Name"
        autoFocus
        variant="outlined"
        className={classes.textFieldPortfolio}
        value={form.name}
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        multiline
        disabled={loading}
        onChange={handleChange}
        data-cy="portfolio-name"
      />
    </ModalWrapper>
  );
};

export default PortfolioModal;
