/* eslint-disable no-new */
import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutocomplete = ({
  items,
  updateUrlParams,
  urlParams,
  label,
  placeholder,
  disabled
}) => (
  <Autocomplete
    disabled={disabled}
    multiple
    options={items}
    id={`${label}-filter`}
    openOnFocus
    getOptionLabel={(option) => option.name}
    value={items.filter((item) => urlParams.includes(item.id))}
    renderTags={(values, getTagProps) => {
      if (values.length > 1) {
        return (
          <Typography style={{ marginLeft: 14, color: '#5C5C5C' }}>
            {`${urlParams.length} Selected`}
          </Typography>
        );
      }
      return values.map((value, index) => (
        <Chip
          key={value.id}
          label={value.name}
          {...getTagProps({ index })}
          style={{ backgroundColor: '#e0e0e0' }}
        />
      ));
    }}
    renderOption={(option, { selected }) => (
      <>
        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color="primary" />
        {option.name}
      </>
    )}
    style={{ width: 400 }}
    onChange={(event, values) => updateUrlParams(values.map((item) => item.id))}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        placeholder={urlParams.length > 0 ? null : placeholder}
      />
    )}
  />
);
export default CustomAutocomplete;

CustomAutocomplete.defaultProps = {
  items: [],
  urlParams: [],
  label: '',
  placeholder: '',
  disabled: false
};

CustomAutocomplete.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  urlParams: PropTypes.arrayOf(PropTypes.string),
  updateUrlParams: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};
