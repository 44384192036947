import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import { validateEmail } from 'helpers/utils';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const getForm = (tenant) => ({
  companyname: tenant.companyname ?? '',
  tradingname: tenant.tradingname ?? '',
  email: tenant.email ?? '',
  position: tenant.position ?? '',
  phone: tenant.phone ?? '',
  headofficeaddress: tenant.headofficeaddress ?? '',
  country: tenant.country ?? '',
  city: tenant.city ?? '',
  postcode: tenant.postcode ?? '',
  street: tenant.street ?? '',
  notes: tenant.notes ?? '',
  industry: tenant.industry ?? 'food',
  maincontact: tenant.maincontact ?? '',
  id: tenant.id ?? '',
  tenantcontractid: tenant.tenantcontractid ?? ''
});

const LeasingTenantDetailsEdit = ({ open, handleClose, tenant }) => {
  const classes = useStyles();

  const [form, setForm] = useState(getForm(tenant));
  const [formCopy, setFormCopy] = useState(form);
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState(false);
  const loading = useSelector((state) => state.leasingManager.details.loading);

  useEffect(() => {
    if (form.id === tenant.id) {
      return;
    }

    setForm(tenant);
  }, [tenant]);

  const handleChange = (name, value) => {
    if (emailError) {
      setEmailError(false);
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = () => {
    if (!validateEmail(form.email) && form.email !== '') {
      toast.error('Invalid e-mail!');
      setEmailError(true);
      return;
    }

    dispatch(actions.updateTenantLeasingDetails(form))
      .then(() => {
        toast.success('Details Updated');
        setFormCopy(form);
      })
      .then(() => {
        dispatch(actions.getTenantLeasingDetails(form.tenantcontractid));
        handleClose();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsiveXL}
      >
        <Typography className={classes.title}>Tenant Details</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <TextField
          label="Head Office Address"
          fullWidth
          variant="outlined"
          value={form.headofficeaddress}
          disabled={loading}
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          onChange={(e) => handleChange('headofficeaddress', e.target.value)}
        />

        <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
          <TextField
            label="Main Contact"
            variant="outlined"
            className={classes.textField}
            style={{ width: '48%' }}
            value={form.maincontact}
            id="maincontact"
            disabled={loading}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            onChange={(e) => handleChange('maincontact', e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Position"
            style={{ width: '48%' }}
            className={classes.textField}
            value={form.position}
            disabled={loading}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            onChange={(e) => handleChange('position', e.target.value)}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
          <TextField
            variant="outlined"
            label="E-mail"
            type="email"
            style={{ width: '48%' }}
            className={classes.textField}
            value={form.email}
            id="email"
            disabled={loading}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            error={emailError}
            onChange={(e) => handleChange('email', e.target.value)}
          />
          <TextField
            label="Phone"
            variant="outlined"
            className={classes.textField}
            style={{ width: '48%' }}
            value={form.phone}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            id="phone"
            onChange={(e) => handleChange('phone', e.target.value)}
          />
        </Box>

        <TextField
          variant="outlined"
          label="Notes"
          fullWidth
          value={form.notes}
          disabled={loading}
          id="notes"
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          onChange={(e) => handleChange('notes', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <CancelButton onClick={handleClose} />
          <SaveButton onClick={handleSave} disabled={isEqual(formCopy, form)} loading={loading} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(LeasingTenantDetailsEdit);

LeasingTenantDetailsEdit.defaultProps = {
  tenant: {}
};

LeasingTenantDetailsEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tenant: PropTypes.objectOf(PropTypes.any)
};
